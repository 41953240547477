<template>
  <div>
    <div @mouseenter="onMouseEnter" @mouseleave="onMouseLeave" class="d-flex" style="cursor: pointer">
      <slot></slot>
      <div style="disable: block; min-width: 30px; height: 100%">
        <v-icon
          :class="`ml-${mlIcon}`"
          @click="$emit('onEdit')"
          v-show="isVisibleEdit"
          :size="sizeIcon"
          :disabled="disableState"
          >mdi-pencil-outline</v-icon
        >
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    sizeIcon: {
      default: 20,
      type: Number,
    },
    mlIcon: {
      default: 2,
      type: Number,
    },
    disableState: Boolean,
  },
  data() {
    return {
      isVisibleEdit: false,
    }
  },
  methods: {
    onMouseEnter() {
      this.isVisibleEdit = true
    },
    onMouseLeave() {
      this.isVisibleEdit = false
    },
  },
}
</script>
